<template>
<div class="layout">
    <home-header></home-header>
  <section>
    <router-view/>
  </section>
  <home-footer></home-footer>
</div>
</template>

<script>
import HomeHeader from "./home-header";
import homeFooter from './home-footer'
export default {
  name: "index",
  components: {HomeHeader,homeFooter}
}
</script>

<style scoped lang="scss">
section{
}
</style>
