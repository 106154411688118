<template>
  <div class="contact-us">
    <div v-if="isHome">
      <div class="isTitle">我们将为您提供更多解决方案</div>
      <div class="isText">
        <router-link to="/consulting?type=1">
          <span>联系我们</span>
          <img src="../../assets/blank-right.png" alt="" />
        </router-link>
      </div>
    </div>
    <div v-else>
      <div class="noHomeText">
        <router-link to="/consulting?type=1">
          <span>联系我们</span>
          <img src="../../assets/blank-right.png" alt="" />
        </router-link>
      </div>
      <div class="noHomeTitle">我们将为您提供全栈式的解决方案</div>
      <div class="noHomeInfo">
        如果需要成为代理商可拨打电话：400 - 965 -
        6365咨询，或告知您的需求，我们将竭诚为您服务。
      </div>
    </div>
    <div class="footer">
      <div class="footer-content">
        <div class="item">
          <div class="left">
            <div class="title">服务热线:</div>
            <div class="title" style="color: #fff">400 - 965 - 6365</div>
            <div>周一到周五9:30-18:00（北京时间)</div>
            <div style="margin-top: 30px" class="text">
              联系我们：0755-26727202
            </div>
            <div class="text">商务合作：business@softsz.com</div>
            <div class="text">媒体合作：market@softsz.com</div>
          </div>
          <div class="right">
            <div class="right-item">
              <div class="title">行业方案</div>
              <div v-for="(item, index) in planList" :key="index">
                <router-link to="/plan">{{
                  item.solutionTypeName
                }}</router-link>
              </div>
            </div>
            <div class="right-item">
              <div class="title">技术产品</div>
              <div
                v-for="(item, index) in goodsList"
                @click="goGoodsList(item)"
              >
                {{ item.label }}
              </div>
            </div>
            <div class="right-item">
              <div class="title">项目案例</div>
              <div v-for="(item, index) in projectList" :key="index">
                <router-link to="/projectList">{{
                  item.caseTypeName
                }}</router-link>
              </div>
            </div>
            <div class="right-item">
              <div class="title">
                <router-link to="/companyProfile?type=1">简介概述</router-link>
              </div>
              <div>
                <router-link to="/companyProfile?type=2">战略主轴</router-link>
              </div>
              <div>
                <router-link to="/companyProfile?type=3"
                  >研究与应用</router-link
                >
              </div>
              <div>
                <router-link to="/companyProfile?type=4">发展历程</router-link>
              </div>
              <div>
                <router-link to="/news">官网新闻</router-link>
              </div>
              <div>
                <router-link to="/recruitment">人才招聘</router-link>
              </div>
            </div>
            <div class="right-item">
              <div class="title">
                <router-link to="/consulting?type=1">合作咨询</router-link>
              </div>
              <div>
                <router-link to="/consulting?type=1">想代理</router-link>
              </div>
              <div>
                <router-link to="/consulting?type=2">想合作</router-link>
              </div>
              <div>
                <router-link to="/consulting?type=3">资料下载</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="item" style="margin-top: 80px">
          <div class="left">
            <div class="addr-box">
              <div>总部：深圳市龙华区清祥路宝能科技园7栋A座6楼</div>
              <div>北京：北京市海淀区紫竹院路116号嘉豪国际中心</div>
              <div>天津：天津市河西区黑牛城道13号双迎大厦</div>
              <div>包头：内蒙古自治区包头市青山区青东华庭</div>
              <div>太原: 太原市五一路御庭华府一号楼</div>
              <div></div>
            </div>
          </div>
          <div class="right">
            <div class="introduce-content">
              <div class="introduce-text">多维感知</div>
              <div class="round"></div>
              <div class="introduce-text">主动预警</div>
              <div class="round"></div>
              <div class="introduce-text">智慧赋能</div>
              <div class="round"></div>
              <div class="introduce-text">服务百业</div>
            </div>
            <div class="focus-on">
              <span>关注我们</span>
              <img src="../../assets/weixin.png" alt="" />
              <div>
                <img src="../../assets/gongzhonghao.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="for-the-record">
          <div>
            <img
              width="160px"
              height="30px"
              src="../../assets/img.png"
              alt=""
            />
            <span style="vertical-align: top; margin-left: 30px"
              >Copyright 2019 ANRUANKEJI SOFTSZ
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                style="color: #ababab"
                >粤ICP备2023045543号-2</a
              ></span
            >
          </div>
          <div>铸行业利器 促社会和谐</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-footer",
  data() {
    return {
      planList: [],
      projectList: [],
      goodsList: [],
      isHome: true,
    };
  },
  created() {
    this.getPlanList();
    this.getProjectList();
    this.getGoodsList();
  },
  watch: {
    $route: {
      handler: function (val) {
        if (val.name != "Home") {
          this.isHome = false;
        } else {
          this.isHome = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    getPlanList() {
      this.$get("/website/solution/group/list")
        .then((res) => {
          this.planList = res.rows;
        })
        .catch((err) => {
          this.msgError(err.msg);
        });
    },
    getProjectList() {
      this.$get("/website/case/group/list ")
        .then((res) => {
          this.projectList = res.rows;
        })
        .catch((err) => {
          this.msgError(err.msg);
        });
    },
    getGoodsList() {
      this.$get("/website/product/category/treeselect")
        .then((res) => {
          this.goodsList = res.data;
        })
        .catch((err) => {
          this.msgError(err.msg);
        });
    },
    goGoodsList(item) {
      this.$router.push({ name: "goodsList" });
    },
  },
};
</script>

<style scoped lang="scss">
.contact-us {
  margin-top: 96px;
  text-align: center;
  background: #ededed;
  .isTitle {
    font-weight: 400;
    font-style: normal;
    font-size: 48px;
    color: #333;
    padding-top: 96px;
  }
  .noHomeTitle {
    font-weight: 400;
    font-style: normal;
    font-size: 48px;
    color: #333;
  }
  .noHomeText {
    font-weight: 400;
    font-style: normal;
    color: #333;
    font-size: 24px;
    padding-top: 96px;
    padding-bottom: 30px;
    img {
      width: 26px;
      height: 26px;
      vertical-align: middle;
    }
  }
  .noHomeInfo {
    color: #797979;
    width: 500px;
    margin: 0 auto;
    padding: 30px 0 96px 0;
  }
  .isText {
    padding: 30px 0 96px 0;
    font-weight: 400;
    font-style: normal;
    color: #333;
    font-size: 24px;
    img {
      width: 26px;
      height: 26px;
      vertical-align: middle;
    }
  }
  a {
    text-decoration: none;
    color: black;
  }
}
.footer {
  background: rgba(0, 0, 0, 1);
  padding: 60px 0;
  .footer-content {
    width: 80%;
    min-width: 1200px;
    margin: 0 auto;
    color: #fff;
    .item {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      .title {
        font-size: 32px;
        color: #fff;
      }
      .left {
        text-align: left;
        color: #aeaeae;
        .title {
          font-size: 28px;
          color: #aeaeae;
        }
        .text {
          font-size: 16px;
          color: #aeaeae;
          margin-top: 10px;
          margin-bottom: 0;
        }
        .addr-box {
          height: 65px;
          font-size: 14px;
          overflow-y: auto;
          padding-left: 10px;
          border-left: 2px solid #797979;
          &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
            height: 30px;
          }
          &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 0px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background-color: rgba(0, 0, 0, 0.2);
          }
          &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            // border-radius: 10px;
            background: transparent;
          }
        }
      }
      .right {
        width: 800px;
        .title {
          font-weight: 400;
          font-style: normal;
          font-size: 22px;
          color: #ffffff;
        }
        text-align: left;
        display: flex;
        justify-content: space-between;
        .right-item {
          font-size: 14px;
          .title {
            font-size: 16px;
            color: #fff;
            a {
              font-size: 16px;
              color: #fff;
            }
          }
          > div {
            margin-bottom: 10px;
            color: #aeaeae;
            cursor: pointer;
          }
          a {
            text-decoration: none;
            color: #aeaeae;
            font-size: 14px;
          }
        }
        .introduce-content {
          display: flex;
          align-items: center;
          color: #fff;
          .introduce-text {
            font-weight: 400;
            font-style: normal;
            font-size: 28px;
            color: #fff;
          }
          .round {
            width: 5px;
            height: 5px;
            background: #fff;
            border-radius: 50%;
            margin: 0 20px;
          }
        }
        .focus-on {
          display: flex;
          align-items: center;
          color: #ababab;
          cursor: pointer;
          img {
            vertical-align: middle;
            width: 43px;
            height: 43px;
            margin-left: 10px;
          }
          position: relative;
          > div {
            position: absolute;
            left: -200px;
            display: none;
            img {
              width: 180px;
              height: 200px;
            }
          }
        }
        .focus-on:hover {
          > div {
            display: block;
          }
        }
      }
    }
  }
  .for-the-record {
    border-top: 1px solid #797979;
    color: #ababab;
    margin-top: 50px;
    padding: 30px 30px;
    display: flex;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
  }
}
</style>
